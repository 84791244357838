'use strict';

var progress = require('./progress');
var util = require('./util');
var AccessibilityHelper = require('../helper/accessibility-helper');

/* FASCILE MODAL */
var wW; var wC; var wH; var mH; var mC; var maxMH; var vidSize; var zIndexCount = 99999;

var facilemodal = {

	init: function() {

		// If auto-generating modal is present
		$(".fm").each(function() {
			if ($(this).attr("data-timeout")) {
				/* eslint-disable camelcase */
				var modal_ID = "#"+$(this).attr("id");
				var modal_W = $(this).attr('data-modal-width');
				var modal_Wp = $(this).attr('data-modal-width-percent');
				var modal_H = $(this).attr('data-modal-height');
				var modal_WMax = $(this).attr('data-modal-width-max');
				var video_Src = facilemodal.getVideoSources($(this));
				var timeout = $(this).attr('data-timeout');
				var _url = $(this).data("href");
				/* eslint-enable camelcase */

				setTimeout(function() {
					facilemodal.showModal(modal_ID, _url, modal_W, modal_Wp, modal_H, modal_WMax, video_Src);
				}, timeout);
			}
		});

		$(".fm-trigger").each(function() {
			if ($(this).attr("data-timeout")) {
				/* eslint-disable camelcase */
				var modal_ID = $(this).attr("href");
				var modal_W = $(this).attr('data-modal-width');
				var modal_Wp = $(this).attr('data-modal-width-percent');
				var modal_H = $(this).attr('data-modal-height');
				var modal_WMax = $(this).attr('data-modal-width-max');
				var video_Src = facilemodal.getVideoSources($(this));
				var timeout = $(this).attr('data-timeout');
				var _url = $(this).data("href");
				/* eslint-enable camelcase */

				setTimeout(function() {
					facilemodal.showModal(modal_ID, _url, modal_W, modal_Wp, modal_H, modal_WMax, video_Src);
				}, timeout);
			}
		});

		/* FASCILE MODAL */
		$(window).on('ready resize', function() {
			facilemodal.getSizes();
		});

		// Click event for modal trigger
		$('body').on('click', '.fm-trigger', function(e) {
			e.preventDefault();
			// We do not want any even bubbling as we only want the modal to launch
			// and not for instance, check a checkbox if a link was in a label.
			e.stopPropagation();

			openModal($(this));
		});

		$('body').on('keydown', '.fm-trigger', function(e) {
			var $this = $(this);
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				e.preventDefault();
				e.stopPropagation();
				openModal($this);
			});
		});


		var openModal = function($this) {
			/* eslint-disable camelcase */
			var modal_ID = $this.attr('href');
			var modal_W = $this.attr('data-modal-width');
			var modal_Wp = $this.attr('data-modal-width-percent');
			var modal_H = $this.attr('data-modal-height');
			var modal_WMax = $this.attr('data-modal-width-max');
			var video_Src = facilemodal.getVideoSources($this);
			/* eslint-enable camelcase */
			var _url = $this.data("href");

			facilemodal.showModal(modal_ID, _url, modal_W, modal_Wp, modal_H, modal_WMax, video_Src);
		};

		// Close button and modal mask trigger closeModal function
		$('body').on('click touchstart keypress', '.modal-box .close-box, .fvm .close, #modal-mask, .fm #cancelBtn, .fm .cancel, .modal-box .close-trigger', function(e) {
			e.preventDefault();
			if (e.type != "keypress" || (e.type == "keypress" && (e.which == 13 || e.which == 32))) {
				facilemodal.closeFmModal($(this).closest(".modal-box"));
			}
		});

		// Escape key triggers closeModal function
		$(document).on('keydown', function(e) {
			e = e || window.event;
			if (e.keyCode == 27) {
				facilemodal.closeFmModal();
			}
		});
	},

	getVideoSources: function($element) {
		var ytSrc = $element.attr('data-yt-id');
		var portraitYtsrc = $element.attr('data-m-yt-id');
		return ytSrc ? {
			landscape: ytSrc,
			portrait: portraitYtsrc
		} : null;
	},

	// Get window size and video player sizes
	getSizes: function() {
		wW = $(window).width();
		wH = $(window).height();
		maxMH = (wH * 0.8);

		// Set variable based on breakpoints
		if (wW >= 1200) {
			vidSize = "xl";
		} else if (wW >= 900 && wW <= 1199) {
			vidSize = 'lg';
		} else if (wW >= 768 && wW <= 899) {
			vidSize = 'md';
		} else if (wW >= 460 && wW <= 767) {
			vidSize = 'sm';
		} else if (wW <= 459) {
			vidSize = 'xs';
		}
	},

	// Show modal
	// To use % based widths from CSS with or without media queries do the following:
	// Take the text from the ID in your modal popup <div id="size-guide-mobile" class="fm size-guide-modal">
	// and create css styling as follows: .size-guide-mobile-box.modal-box {width: 90%;}
	// This will force the showModal function to use that styling.

	showModal: function(ID, url, mW, mWp, maxH, mWMax, videoSrc, CB) {

		var _id = ID.replace("#", "");
		var modalBoxClassName = "."+_id+"-box";
		var maskContent = '<div id="modal-mask"></div>';

		// Window width and height need to be initialized
		if (!wW || !wH) {
			facilemodal.getSizes();
		}

		// Add mask to page
		if ($("#modal-mask").length == 0) {
			if ($(ID).length == 0) {
				$(".main-box").prepend(maskContent);
			} else {
				// pre-define modal content, normally in BM content asset
				if ($(modalBoxClassName).length == 0) {
					// insert mask before modal info content if no encapsulating box exist
					$(ID).before(maskContent);
				} else {
					// a modal box already existed, if the modal was opened before
					// therefore, insert the mask before the box
					$(modalBoxClassName).before(maskContent);
				}
			}

			$("#modal-mask").hide().fadeIn(250);
			$("body").addClass("facile-modal-open");
		}

		// Check if this is an ajax based modal, if so, delete any previous container and re-create.
		if (url) {
			$(modalBoxClassName).remove();
		}

		// Check to see if initial container exists, if not, create it on the body.
		if ($(ID).length == 0) {
			$("<div id='"+_id+"' class='fm'></div>").appendTo(".main-box");
		}

		// Add modal class to div for styling
		$(ID).addClass('modal');

		// Video modal styles are handled differently so no need to call these functions for video modals
		if (!videoSrc) {
			var $box = null;

			if (typeof ID === 'string' || ID instanceof String) {
				_id = ID.replace("#", "");
			} else {
				_id = ID[0].id;
			}

			var setPosition = function() {

				// This allows multiple modals to stack on top of each other
				if ($(".modal-box").length > 0) {
					zIndexCount = zIndexCount+1;
					$box.attr("style", "z-index:"+zIndexCount+" !important");
				}

				// Add close button to modal
				$box.prepend("<div tabindex='0' role='button' class='close-box icon icon-icon-close focusable' aria-label='close'></div>");

				$box.find(".close-box").focus();

				// CALCULATE WIDTH OF MODAL
				// if data-modal-width-percent (mWp) is provided then the width will be calculated based on the screen width and this %.
				// If data-modal-width (mW) is provided then this will be the width used if (mWp) is not provided OR if the calculated width from (mWp) is greater than (mW)

				// If (mW) and (mWp) is not provided, then use a width of 80% to a maximum of 800px.
				if (!mW && !mWp) {
					mW = 800;
					mWp = 80;
				}
				var calcW = 0;

				// Calculate % based width if provided.
				if (mWp) {
					calcW = wW * mWp / 100;
				}

				// Calculate width to provided width (mW) if no % value (mWp) passed or resulting width from (mWp) is greater than (mW).
				if (mW) {
					if (calcW == 0 || calcW > mW) {
						if (mW < wW) {
							calcW = mW; // Don't set modal width greater than window width
						} else {
							calcW = wW * .9; // Done ever set calculated with greater than 90% of window width
						}
					}

					// If a max width is set, do not allow anything larger.
					if (mWMax) {
						if (calcW > mWMax) {
							calcW = mWMax;
						}
					}
				}

				// Calculate width to value from css from modal box that was created (e.g. .size-guide-mobile-box.modal-box )
				if (calcW == 0) {
					calcW = $box.css('width');
				}

				// Show modal content
				$(ID).fadeIn(250);

				// If a max width is set, do not allow anything larger.
				calcW = parseInt(calcW);
				if (mWMax) {
					if (calcW > mWMax) {
						calcW = mWMax;
					}
				}

				// Set calculated width to modal window
				var modalMargin = (((1 - (calcW/wW)) / 2) * 100);
				$box.css('left', modalMargin + '%');
				$(ID).css('width', calcW);

				$(window).on('resize', function() {
					calcW = parseInt(calcW);
					modalMargin = (((1 - (calcW/wW)) / 2) * 100);
					$box.css('left', modalMargin + '%');
					$(ID).css('width', calcW);
				});
			};

			var setHeight = function() {
				// (Re)set natural height
				$(ID).css('height', 'auto');
				mH = maxH || $box.height();
				if (mH < maxMH) {
					// If modal height is less than modal max height calculate to center
					wC = (wH/2);
					mC = (wC - (mH/2));
					$box.css('top', mC + 'px' );
					$(ID).css('height', mH );
				} else {
					// if modal height is equal or larger than modal max height set modal to max height and set margin to 10% (max height it 80% so 10% makes it vertically centered)
					$box.css('top', '10%' );
					$(ID).css('height', maxMH );
				}
			};

			if ($(modalBoxClassName).length == 0) {
				$box = $("<div class='"+_id+"-box modal-box'></div>");
				$box.insertBefore(ID);

				if (url) {

					// Open a blank modal and show a progress indicator.
					progress.show($(ID));
					$(ID).appendTo($box);
					setPosition();
					setHeight();

					$.get(url, function( data ) {
						if (data) {
							$(ID).empty();
							// iPhone XS issue - container was not recognizing
							// the overflow (hence unable to scroll) of the injected content that came from an AJAX call.
							// Put a little delay between removing the progress widget and adding content.
							setTimeout(function() {
								$(ID).html(data);
								setHeight();

								// Trigger lazyload update, just in case there are lazy images in the modal
								window.lazyLoadImgSrcInstance && window.lazyLoadImgSrcInstance.update();
								window.lazyLoadCssBgInstance && window.lazyLoadCssBgInstance.update();

								// Call Generic Function if it is passed.
								if (CB) {
									CB.call();
								}
							}, 100);

						}
					});
				} else {
					$(ID).appendTo($box);
					setPosition();
					setHeight();

					window.lazyLoadImgSrcInstance && window.lazyLoadImgSrcInstance.update();
					window.lazyLoadCssBgInstance && window.lazyLoadCssBgInstance.update();

					// Call Generic Function if it is passed.
					if (CB) {
						CB.call();
					}
				}
			} else {
				$box = $(modalBoxClassName);
				setPosition();
				setHeight();

				window.lazyLoadImgSrcInstance && window.lazyLoadImgSrcInstance.update();
				window.lazyLoadCssBgInstance && window.lazyLoadCssBgInstance.update();

				// Call Generic Function if it is passed.
				if (CB) {
					CB.call();
				}
			}

		} else {
			// get youtube id if link is provided, otherwise use as id directly
			var ytId = util.getYouTubeIdFromLink(videoSrc.landscape) || videoSrc.landscape;
			// If data-yt-id is declared get aspect ratio, generate embed code for video
			// Get aspect ratio of video via youtube static image
			$('body').append("<img style='visibility: hidden !important; z-index:-1' id='fm-video-image' src='https://i.ytimg.com/vi/" + ytId + "/maxresdefault.jpg' />");

			setTimeout(function() {
				facilemodal.embedVideo(videoSrc);
			}, 350);
			// Remove image after video size has been calculated
			setTimeout(function() {
				$('#fm-video-image').remove();
			}, 600);
		}
	},

	// Embed video
	embedVideo: function(videoSrc) {
		/* eslint-disable camelcase */
		var chosenVid = videoSrc.landscape;

		// set default aspect ratio
		var factor = 9/16;
		var portraitFactor = 16/9;

		// get real landscape video aspect ratio
		var video_H = $('#fm-video-image').css('height');
		var video_W = $('#fm-video-image').css('width');
		video_H = parseFloat(video_H);
		video_W = parseFloat(video_W);
		if (video_H && video_W) {
			factor = video_H / video_W;
		}

		var embed_W;
		var embed_H;

		if (vidSize == 'xl') {
			// maximum 1600px wide on xlarge screen
			embed_W = Math.min(wW * 0.8, 1600);
		} else if (vidSize == 'lg') {
			embed_W = 800;
		} else if (vidSize == 'md') {
			embed_W = 700;
		} else if (vidSize == 'sm') {
			// minimum 400px wide on sm screen
			embed_W = Math.max(wW * 0.7, 400);
		} else if (vidSize == 'xs') {
			// minimum 300px wide on phone screen
			embed_W = Math.max(wW * 0.8, 300);
		}

		if ((vidSize == 'xs' || vidSize == 'sm') && videoSrc.portrait) {
			// use portrait video on phone screen if provided
			chosenVid = videoSrc.portrait;
			embed_H = Math.round(embed_W * portraitFactor);
			// shrink video modal to avoid scrolling inside modal
			if (embed_H > maxMH) {
				embed_W = Math.round(maxMH / portraitFactor);
				embed_H = maxMH;
			}
		} else {
			// calc height based on landscape aspect ratio
			chosenVid = videoSrc.landscape;
			embed_H = Math.round(embed_W * factor);
			// shrink video modal to avoid scrolling inside modal
			if (embed_H > maxMH) {
				embed_W = Math.round(maxMH / factor);
				embed_H = maxMH;
			}
		}

		// Append Modal Code
		var $ytIframeEl = $('#modal-mask').append("<div class='fvm modal'><div class='embed-container' style='width:" + embed_W + "px;'></div></div>");

		// inject iframe using js script, because autoplay may not work if iframe src is injected as part of html
		var iframe = document.createElement('iframe');
		iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
		iframe.setAttribute('allowfullscreen', true);
		iframe.setAttribute('frameborder', '0');
		iframe.setAttribute('height', embed_H);
		var isYtLink = !!util.getYouTubeIdFromLink(chosenVid);
		// video source is a link, if we can get youtube id from the source
		// video source is an id, if getYouTubeIdFromLink return empty
		var ytUrl = isYtLink ? chosenVid : '//www.youtube.com/embed/' + chosenVid + '?rel=0&autoplay=1';
		iframe.setAttribute('src', ytUrl);
		iframe.setAttribute('width', embed_W);
		iframe.classList.add('modal-video-embed');
		$ytIframeEl.find('.embed-container').get(0).appendChild(iframe);

		// If modal height is less than modal max height calculate to center
		if (embed_H <= maxMH) {
			wC = (wH/2);
			mC = (wC - (embed_H/2));
			$('.fvm').css('top', mC + 'px' );
		// If modal height is more than modal max height set modal to max height and set margin to 10% (max height it 80% so 10% makes it veritcally centered)
		} else if (embed_H > maxMH) {
			$('.fvm').css('top', '5%' );
			// $('.fvm').css('height', maxMH );
		}
		// Add close button to modal
		$('.embed-container').prepend("<span class='close' href='close'></span>");
	},
	/* eslint-enable camelcase */

	// Removes modal mask, close button, and video embed as soon as modal is faded out
	closeFmModal: function() {

		if ($(".modal-box").is(":visible")) {
			var openedModalIds = [];
			$(".modal-box").each(function() {
				var id = $(this).attr("id");
				if (id && $("#"+id).is(":visible")) {
					openedModalIds.push(id);
				}
			});
			$(".modal-box").fadeOut(250, function() {
				$(this).attr("style", "z-index: -1; display: none;");
				openedModalIds.forEach((id) => {
					document.dispatchEvent(new Event(id + '-closed'));
				});
			});
		} else {
			$('.fm').hide();
		}

		$("body").removeClass("facile-modal-open");
		$('#modal-mask').fadeOut(250);
		setTimeout( function() {
			$('#modal-mask, .modal-video-embed, .close-box').remove();
		}, 300);
	}

};

module.exports = facilemodal;
