'use strict';

var Cleave = require('cleave.js/dist/cleave.js');

var formPrepare = require('../../components/formPrepare');
var util = require('../../components/util');
var progress = require('../../components/progress');
var accordion = require('../../components/accordion');
var address = require('../../components/address');
var ccTypePattern = require('../../components/cc-type-pattern');
var gtmTracking = require('../../components/gtmTracking');
var inputs = require('../../components/inputs');
var checkout = require('./index');

var _paymentMethodID = "";
var PAYMENT_SELECTION_EVENT = 'icebreaker:paymentMethodSelected';
var PAYMENT_METHODS_UPDATED_EVENT = 'icebreaker:paymentMethodsUpdated';
var _previousGCPIN = "";

var $billingForm = $("[id$='_billing']");
var $firstName = $("input[name$='_firstName']");
var $lastName = $("input[name$='_lastName']");
var $companyName = $("input[name$='companyName']");
var $address1 = $("input[name$='_address1']");
var $address2 = $("input[name$='_address2']");
var $zip = $("input[name$='_zip']");
var $city = $("input[name$='_city']");
var $country = $("select[name$='_country']");
var $state = $("select[name$='_states_state']");
var $email = $("input[name$='_emailAddress']");
var $phone = $("input[name$='_phone']");

var $ccOwner = $("input[name$='paymentMethods_creditCard_owner']");
var $ccType = $("select[name$='paymentMethods_creditCard_type']");
var $ccNo = $("input[name$='paymentMethods_creditCard_number']");
var $ccMonth = $("input[name$='paymentMethods_creditCard_month']");
var $ccYear = $("input[name$='paymentMethods_creditCard_year']");
var $ccCVV = $("input[name$='paymentMethods_creditCard_cvn']");
var ccMonthYearSelector = "input[name$='paymentMethods_creditCard_monthAndYear']";
var $ccMonthYear = $(ccMonthYearSelector);

var $invoiceWrapper = $('#billing-invoice-wrapper');
var validateInvoicePECorRecipient;
var cleaveMonthYear;

/**
 * @function initPromoWhy()
 * @description
 * init Tipped for promo Why tooltip
 */
var initPromoWhy = function () {
	Tipped.create('.promo-why', 'promoWhyTooltip', {inline: true, skin: 'white', maxWidth: 200});
};

/**
 * @function promoCode()
 * @description
 */
var promoCode = function (href, refresh, empty) {
	// Empty Passed
	if (empty) {
		$(".promo-code-entry .coupon-error").hide();
		$(".promo-code-entry .coupon-error-missing").show();
		$(".promo-code-entry .inputfield").addClass("field-error");
		return;
	}

	progress.show($('.promocodes'));
	progress.show($('.checkout-summary'));

	// Update Promo form
	$.ajax(refresh).done(function (FormData) {
		if (FormData) {
			$('.promocodes').replaceWith(FormData);

			initPromoWhy();
		} else {
			progress.hide($('.promocodes'));
		}

		// Update order summary (nested on purpose)
		$.ajax(href).done(function (summaryData) {
			if (summaryData) {
				updateCheckoutSummaryHtml(summaryData);
				updatePaymentMethods();
			} else {
				progress.hide($('.checkout-summary'));
			}
		});
	});
};

var updateCheckoutSummaryHtml = function (summaryHtml) {
	$('.checkout-summary').html(summaryHtml);
	// Clones updated order-summary for second prices (mobile)
	// The order summary below the payment boxes is only a subset (`.order-info`)
	// of the summary in the right side column (from minisummary.isml)
	const summarySubsetElement = $('.checkout-summary .order-summary .order-info');
	let subsetSummaryHtml;
	if (summarySubsetElement.length > 0) {
		subsetSummaryHtml = summarySubsetElement[0].innerHTML;
	} else {
		subsetSummaryHtml = "";
	}
	$('.billing-summary .order-summary').html(subsetSummaryHtml);

	checkout.refreshTooltips();
};

/**
 * @function processGiftCard()
 * @description
 */
var processGiftCard = function () {

	var $code = $("input[name$='_giftCertCode']");
	var $pin = $("input[name$='_giftCertPin']");

	// Clear errors and any pre-existing data if any
	$(".giftcert-error").html("").hide();
	$(".gift-cert-form .gc-info").hide();
	$(".gift-cert-form .balance-error").html("").hide();

	if ($code.val().length === 0) {
		$(".giftcert-error").html(Resources.GIFT_CERT_MISSING);
		$(".giftcert-error").show();
		return;
	}

	var _giftCardNo = $code.val();
	var _giftCardPin = $pin.val();
	var _pinrequired = $("input[name*=_giftCertPin]").data("pinrequired") == "true";

	if (_giftCardNo && /^[a-zA-Z0-9]+$/.test(_giftCardNo)) {
		$(".gift-cert-form .giftcertapply-loading").show();

		if (_previousGCPIN != _giftCardNo) {
			_pinrequired = false;
		}
		_previousGCPIN = _giftCardNo;

		if (!_pinrequired) {
			// Check if gift certificate requires a PIN
			$.getJSON(Urls.checkGiftCardNo, {giftCardNo: _giftCardNo}).done(function (data) {
				$(".gift-cert-form .giftcertapply-loading").hide();
				if (data && data.error == false) {
					if (data.pinrequired == false) {
						$("input[name$=_giftCertPin]").data("pinrequired", "false");
						$(".gift-cert-form").removeClass("show-pin");
						$(".gift-cert-form .gc-pin-value input.textinput").hide();
						$(".gift-cert-form .gc-pin-value").removeClass("show");
						$(".gift-cert-form .whatisthis").hide();
						redeemGiftCard(_giftCardNo, null);
					} else {
						$("input[name$=_giftCertPin]").data("pinrequired", "true");
						$(".gift-cert-form").addClass("show-pin");
						$(".gift-cert-form .gc-pin-value").addClass("show").fadeIn(1000);
						$(".gift-cert-form .gc-pin-value input.textinput").show().focus();
						$(".gift-cert-form .whatisthis").fadeIn(750);
					}
				}
			});
		} else {
			if (_giftCardPin && _giftCardPin.length == 4 && /^[a-zA-Z0-9]+$/.test(_giftCardPin)) {
				redeemGiftCard(_giftCardNo, _giftCardPin);
			} else {
				$(".gift-cert-form .balance-error").html(Resources.GIFTCERT_BOTH_NO_BLANK).show();
				$(".gift-cert-form .giftcertapply-loading").hide();
			}
		}
	} else {
		if (!_pinrequired) {
			$(".gift-cert-form .balance-error").html(Resources.GIFTCERT_NO_BLANK).show();
		} else {
			$(".gift-cert-form .balance-error").html(Resources.GIFTCERT_BOTH_NO_BLANK).show();
		}
	}
};

/**
 * @function RedeemGiftCard()
 * @description Redeem a gift card
 */
var redeemGiftCard = function (giftCardNo, giftCardPin) {

	var $code = $("input[name$='_giftCertCode']");
	var $pin = $("input[name$='_giftCertPin']");

	$(".gift-cert-form .giftcertapply-loading").show();
	$.getJSON(Urls.redeemGiftCert, {
		giftCertCode: giftCardNo, giftCertPin: giftCardPin, format: 'ajax'
	}).done(function (data) {
		if (data && (data.error != true)) {
			$('.redeemed-giftcert-list').html(data.redeemedGiftCertListHtml);
			updateMiniSummary();
			updatePaymentMethods();
			$code.val('');
			$pin.val('');
		} else {
			// Display error info
			$(".giftcert-error").html(data.message);
			$(".giftcert-error").show();
		}
	}).fail(function (data) {
		$(".giftcert-error").html(data.error).show();
	}).always(function () {
		$(".gift-cert-form .giftcertapply-loading").hide();
		progress.hide();
	});
};

/**
 * Removes the gift card.
 * @param giftCardNo
 * @param giftCardPin
 * @constructor
 */
var removeGiftCard = function (giftCardNo) {
	var $giftCertList = $('.redeemed-giftcert-list');
	progress.show($giftCertList);
	$.getJSON(Urls.removeGiftCert, {
		giftCertificateID: giftCardNo,
		format: 'ajax'
	}).done(function (data) {
		if (data && (data.error != true)) {
			$('.redeemed-giftcert-list').html(data.redeemedGiftCertListHtml);
			updateMiniSummary();
			updatePaymentMethods();
		} else {
			// Display error info
			$(".giftcert-error").html(data.message);
			$(".giftcert-error").show();
		}
	}).fail(function (data) {
		$(".gift-cert-form .giftcertapply-loading").hide();
		$(".giftcert-error").html(data.error).show();
	}).always(function () {
		progress.hide();
	});
};


/**
 * Removes the gift card.
 * @param giftCardNo
 * @param giftCardPin
 * @constructor
 */
var updateMiniSummary = function () {
	progress.show($('.checkout-summary'));
	$.ajax(Urls.summaryRefreshURL, {
		cpt: 'payment'
	}).done(function (minisummaryHtml) {
		if (minisummaryHtml) {
			updateCheckoutSummaryHtml(minisummaryHtml);
		}
	}).always(function () {
		progress.hide();
	});
};


/**
 * @function CheckBalance()
 * @description Check balance on gift cards
 */
var checkBalance = function (giftCardNo, giftCardPin) {
	if (giftCardNo) {
		$(".gift-cert-form .balance-loading").show();
		$.getJSON(Urls.checkBalance, {
			giftCardNo: giftCardNo, giftCardPin: giftCardPin, format: "ajax"
		}).done(function (data) {
			$(".gift-cert-form .balance-loading").hide();

			if (data.giftCertificate) {
				$(".gc-info .gc-no").html(data.giftCertificate.ID);
				$(".gc-info .balance").html(data.giftCertificate.balance);
				$(".gc-info .expires").html(data.giftCertificate.expires);

				$(".gift-cert-form .gc-info").show();
			} else {
				if (data.error) {
					$(".gift-cert-form .balance-error").html(data.error).show();
				}
			}
		}).fail(function (data) {
			$(".gift-cert-form .balance-loading").hide();
			$(".gift-cert-form .balance-error").html(data.error).show();
		});
	}
};

/**
 * @function processGiftCardCheck()
 * @description Check balance on gift cards
 */
var processGiftCardCheck = function () {
	var $giftcertform = $(".gift-cert-form");

	// Clear errors and any pre-existing data if any
	$(".giftcert-error").html("").hide();
	$giftcertform.find(".gc-info").hide();
	$giftcertform.find(".balance-error").html("").hide();

	var _giftCardNo = $("input[name$=_giftCertCode]").val();
	var _giftCardPin = $("input[name$=_giftCertPin]").val();
	var _pinrequired = $("input[name$=_giftCertPin]").data("pinrequired") == "true";

	if (_giftCardNo && /^[a-zA-Z0-9]+$/.test(_giftCardNo)) {

		$giftcertform.find(".balance-loading").show();

		if (_previousGCPIN != _giftCardNo) {
			_pinrequired = false;
		}
		_previousGCPIN = _giftCardNo;

		if (!_pinrequired) {
			// Check if gift certificate requires a PIN
			$.getJSON(Urls.checkGiftCardNo, {giftCardNo: _giftCardNo}).done(function (data) {
				$(".gift-cert-form .balance-loading").hide();
				if (data && data.error == false) {
					if (data.pinrequired == false) {
						$("input[name$=_giftCertPin]").data("pinrequired", "false");
						$(".gift-cert-form").removeClass("show-pin");
						$giftcertform.find(".gc-pin-value input.textinput").hide();
						$giftcertform.find(".gc-pin-value").removeClass("show");
						$giftcertform.find(".whatisthis").hide();
						checkBalance(_giftCardNo, null);
					} else {
						$("input[name$=_giftCertPin]").data("pinrequired", "true");
						$(".gift-cert-form").addClass("show-pin");
						$giftcertform.find(".gc-pin-value input.textinput").show();
						$giftcertform.find(".gc-pin-value").addClass("show").fadeIn(1000);
						$giftcertform.find(".whatisthis").fadeIn(750);

						var _gcPin = $("input[name$=_giftCertPin]").val();
						if (_gcPin != "" && _gcPin.length == 4) {
							checkBalance(_giftCardNo, _gcPin);
						}
					}
				}
			});
		} else {
			if (_giftCardPin && _giftCardPin.length == 4 && /^[a-zA-Z0-9]+$/.test(_giftCardPin)) {
				checkBalance(_giftCardNo, _giftCardPin);
			} else {
				$giftcertform.find(".balance-error").html(Resources.GIFTCERT_BOTH_NO_BLANK).show();
				$giftcertform.find(".balance-loading").hide();
			}
		}

	} else {
		if (!_pinrequired) {
			$giftcertform.find(".balance-error").html(Resources.GIFTCERT_NO_BLANK).show();
		} else {
			$giftcertform.find(".balance-error").html(Resources.GIFTCERT_BOTH_NO_BLANK).show();
		}
	}
};

/**
 * @function changePaymentMethod()
 * @description
 */
var changePaymentMethod = function (paymentMethodID, resetPaypalOrigination, radioBtn) {
	var $buyNow = $("button[name*='billing_save']");

	// Hide all payment forms
	$(".paymentform").hide();

	// Hide Afterpay blurb
	$(".afterpay-blurb").hide();

	// Fire custom event on "#paymentmethods" element.
	// It allows other event handlers to process payment method selection.
	var brandCode = radioBtn ? $(radioBtn).data("brandcode") : null;
	updateBuyNowButtonValue(paymentMethodID, brandCode, $buyNow);

	if (brandCode && brandCode.indexOf("bankTransfer") > -1) {
		// Existing behaviour - hide gift cert entry field on bank transfer selection.
		$(".gift-cert").hide();
	} else {
		$(".gift-cert").show();
	}

	// Mark container div of the selected payment method as "selected"
	$(".payment-block").toggleClass('selected', false);
	radioBtn.parents('.payment-block').toggleClass('selected', true);

	if (resetPaypalOrigination) {
		$("#PaypalOrigination").val("PaymentPage");
		// PayPal express request will be redirected back to the billing page.
		// If the customer selects a different payment method there, we reset
		// the payment origination to "BILLING" so that customer will re-initiate
		// PayPal payment again by logging into their PayPal account.
	}

	$("#paymentmethods", document).trigger(PAYMENT_SELECTION_EVENT, {
		paymentMethodID: paymentMethodID,
		radioBtn: radioBtn,
		brandCode: brandCode,
		$buyNow: $buyNow,
		showAuthorizingSpinner: function (message) {
			showAuthorizingSpinner(message);
		}
	});

	_paymentMethodID = paymentMethodID;
};

function onPaymentMethodSelection(paymentMethodID) {

	// Show only selected payment form
	$("#PaymentMethod_" + paymentMethodID).show();

	if (_paymentMethodID != paymentMethodID) {

		var isDirectPaypalExpress = paymentMethodID == "PayPal" && $("#PaypalOrigination").val() == "ExpressCheckout";
		var isOldAdyenPaypalExpress = paymentMethodID == "paypal" && $("#PaypalOrigination").val() == "ExpressCheckout";

		if (paymentMethodID != 'PayPal') {
			requireAddressForm(true);

			if (paymentMethodID == "CREDIT_CARD") {
				requireCreditCardForm(true);
			} else {
				requireCreditCardForm(false);
			}

			if (paymentMethodID == "AFTERPAY") {
				$(".afterpay-blurb").fadeIn(500);
			}
		} else if (isDirectPaypalExpress || isOldAdyenPaypalExpress) {
			requireAddressForm(true);
			requireCreditCardForm(false);
		} else {
			requireAddressForm(false);
			requireCreditCardForm(false);
		}

		// ensure checkbox of payment method is checked
		if (isOldAdyenPaypalExpress) {
			$("#is-" + paymentMethodID + "-paypal").prop("checked", true);
			$("input[name='brandCode']").val("paypal");
		} else {
			$("#is-" + paymentMethodID).prop("checked", true);
		}
	}
}

var updateBuyNowButtonValue = function (paymentMethodID, brandCode, $buyNow) {
	var resource = Resources.CONFIRM_PAYMENT;
	if (brandCode && brandCode.indexOf("klarna") > -1) {
		resource = Resources.CONTINUE_TO_KLARNA;
	} else if (brandCode && brandCode.indexOf("paypal") > -1) {
		resource = $("#PaypalOrigination").val() == "ExpressCheckout" ? Resources.CONFIRM_PAYPAL_PAYMENT : Resources.CONTINUE_TO_PAYPAL;
	} else if (paymentMethodID == 'PayPal') {
		resource = $("#PaypalOrigination").val() == "ExpressCheckout" ? Resources.CONFIRM_PAYPAL_PAYMENT : Resources.CONTINUE_TO_PAYPAL;
	} else if (paymentMethodID == 'AFTERPAY') {
		resource = Resources.CONTINUE_TO_AFTERPAY;
	}
	$buyNow.attr("value", resource).find('span').text(resource);
};


/**
 * @function requireAddressForm()
 * @description
 */
var requireAddressForm = function (required) {

	if (!required) {
		$firstName.removeClass('required');
		$lastName.removeClass('required');
		$address1.removeClass('required');
		$zip.removeClass('required');
		$city.removeClass('required');
		$country.removeClass('required');
		$state.removeClass('required');
		$phone.removeClass('required');
	} else {
		$firstName.addClass('required');
		$lastName.addClass('required');
		$address1.addClass('required');

		if ($country.val() != "AE") { // United Arab Emirates does not use a zip / postal code
			$zip.addClass('required');
		} else {
			$zip.removeClass('required');
		}

		$city.addClass('required');
		$country.addClass('required');
		$state.addClass('required');
	}

	formPrepare.validateForm();
};

/**
 * @function requireCreditCardForm()
 * @description
 */
var requireCreditCardForm = function (required) {

	if (!required) {
		$("input[name$='paymentMethods_creditCard_owner']").removeClass("required");
		$("input[name$='paymentMethods_creditCard_number']").removeClass("required");
		$("select[name$='paymentMethods_creditCard_month']").removeClass("required");
		$("select[name$='paymentMethods_creditCard_year']").removeClass("required");
		$("input[name$='paymentMethods_creditCard_cvn']").removeClass("required");
	} else {
		$("input[name$='paymentMethods_creditCard_owner']").addClass("required");
		$("input[name$='paymentMethods_creditCard_number']").addClass("required");
		$("select[name$='paymentMethods_creditCard_month']").addClass("required");
		$("select[name$='paymentMethods_creditCard_year']").addClass("required");
		$("input[name$='paymentMethods_creditCard_cvn']").addClass("required");
	}

	formPrepare.validateForm();
};

var initPaymentMethodSelectionEvent = function () {
	// NOTE: PAYMENT_SELECTION_EVENT will be triggered on #paymentmethods element
	// and this handler will be notified last (utilizing event bubbling behaviour).
	// If any existing event listeners call stopPropagation() on the event, this
	// handler won't be called since the payment selection was handled by other listeners.
	$(document).on(PAYMENT_SELECTION_EVENT, "#paymentmethods", function (event, data) {
		// Payment selection was not processed by any other listeners. Process it as usual.
		onPaymentMethodSelection(data.paymentMethodID);
	});
};

/**
 * @function initPaymentMethodSelection()
 * @description Initializes the payment method form
 */
var initPaymentMethodSelection = function () {
	// get selected payment method from payment method form
	var _checkedRadio = $("input[name$='_paymentMethods_selectedPaymentMethodID']:checked");
	var _paymentMethodID = _checkedRadio.val();

	if (!_paymentMethodID) {
		// if necessary fall back to default payment method (first non-gift-certificate method)
		_paymentMethodID = $("#paymentmethods").data("defaultpaymentmethod");
	}
	// show payment method section
	changePaymentMethod(_paymentMethodID, false, _checkedRadio);
};

/**
 * @function clearField()
 * @description clear an input field with validating messages
 */
var clearField = function (elm) {
	elm.val('');
	// remove error/success messaging
	elm.parent(".field-error").removeClass("field-error").find("span.field-error").remove();
	elm.parent(".field-success").removeClass("field-success");
};

/**
 * @function clearAddressForm()
 * @description Selectively clears address form inputs
 */
var clearAddressForm = function () {
	var inputsToClear = [$firstName, $lastName, $companyName, $address1, $address2, $city, $zip];
	var selectsToClear = [$state];

	inputsToClear.forEach(function (element) {
		clearField(element);
	});

	util.checkInputVal(inputsToClear);

	selectsToClear.forEach(function (element) {
		address.changeFormSelection(element[0], '');
	});
};

/**
 * @function populateAddressForm()
 * @description Updates the address form with the attributes of a given address
 */
var populateAddressForm = function (addressID) {
	var _addressID = addressID;

	$.ajax({
		type: "GET",
		dataType: "json",
		cache: false,
		contentType: "application/json",
		url: Urls.getAddressDetails,
		data: {addressID: _addressID}

	}).done(function (data) {

		if (!data || !data.address) {
			// eslint-disable-next-line no-console
			console.error(Resources.ADDRESS_LOAD_ERROR + " " + Resources.PLEASE_SIGNIN);
			return;
		}

		address.changeFormSelection($country[0], data.address.countryCode);
		var _countryCode1 = $country.val();
		var _postalMasks = $(".checkout-billing").data("postalmasking");

		address.changePostalCode(_countryCode1, _postalMasks);

		// fill the form
		$firstName.val(data.address.firstName);
		$lastName.val(data.address.lastName);
		$companyName.val(data.address.companyName);
		$address1.val(data.address.address1);
		$address2.val(data.address.address2);
		$city.val(data.address.city);
		$zip.val(data.address.postalCode);

		address.updateState(_countryCode1, data.address.stateCode);

		$phone.val(data.address.phone);

		// Run validation on all shipping address fields.
		formPrepare.validateBillingForm(true);

		var inputsToCheck = [$firstName, $lastName, $companyName, $address1, $address2, $city, $zip, $phone];
		util.checkInputVal(inputsToCheck);

	}).fail(function (xhr, textStatus) {
		// failed
		if (textStatus === "parsererror") {
			// eslint-disable-next-line no-console
			console.error(Resources.BAD_RESPONSE);
		} else {
			// eslint-disable-next-line no-console
			console.error(Resources.SERVER_CONNECTION_ERROR);
		}
	});
};

/**
 * @function populateCreditCardForm()
 * @description Updates the credit card form with the attributes of a given card
 */
var populateCreditCardForm = function (cardID) {
	$.ajax({
		type: "GET",
		dataType: "json",
		cache: false,
		contentType: "application/json",
		url: Urls.billingSelectCC,
		data: {creditCardUUID: cardID}

	}).done(function (data) {
		if (!data || !data.creditCard) {
			// eslint-disable-next-line no-console
			console.error(Resources.CREDITCARD_LOADERROR);
			return false;
		}

		// Fill the form / clear the former CVV input
		$ccOwner.val(data.creditCard.holder);
		address.changeFormSelection($ccType[0], data.creditCard.type);
		$ccNo.val(data.creditCard.maskedNumber);

		var expirationMonth = String(data.creditCard.expirationMonth);
		expirationMonth = expirationMonth.length == 1 ? '0' + expirationMonth : expirationMonth;
		var expirationYear = data.creditCard.expirationYear.substring(2);


		cleaveMonthYear.setRawValue(expirationMonth + expirationYear);
		$ccMonthYear.attr('data-empty', false);

		$ccCVV.val("").focus();

		// Gray out all credit card fields and disable.
		$ccOwner.removeClass("required").addClass("disabled");
		$ccMonthYear.removeClass("required").addClass("disabled");
		$ccNo.removeClass("required").addClass("disabled");

		$ccOwner.prop("disabled", true);
		$ccNo.prop("disabled", true);
		$ccMonthYear.prop("disabled", true);

		$(".clear-selected-card").show();

		var inputsToCheck = [$ccOwner, $ccNo, $ccCVV];
		util.checkInputVal(inputsToCheck);

		// remove error messaging
		$("#PaymentMethod_CREDIT_CARD span.field-error").remove();
		$("#PaymentMethod_CREDIT_CARD .field-error").removeClass("field-error");

	}).fail(function (xhr, textStatus) {
		// failed
		if (textStatus === "parsererror") {
			// eslint-disable-next-line no-console
			console.error(Resources.BAD_RESPONSE);
		} else {
			// eslint-disable-next-line no-console
			console.error(Resources.SERVER_CONNECTION_ERROR);
		}
	});

};

/**
 * @function setPaymentSection()
 * @description If the whole order total was paid with gift certs then hide
 *              other payment methods and show a message
 */
var setPaymentSection = function (orderBalance) {

	if (orderBalance <= 0) {
		// if the whole order total was paid with gift certs then hide other
		// payment methods and show a message
		$("#paymentmethods").hide();
		// if the order total is zero, determine was it because of the gift
		// certificate use or a promotion? and show appropriate message
		$(".giftcertused").html($(".giftcertpi").length > 0 ? Resources.GIFTCERT_NOMETHOD : Resources.ZERO_BALANCE).show();
	}
};

/**
 * @function updatePaymentMethods()
 * @description updates the payment method section after billing address
 *              country-code changed, coupon code was entered or gift
 *              certificate was redeemed
 */
var updatePaymentMethods = function () {
	var _countryCode = $country.val();
	var _url = Urls.updatePaymentMethods + "?countryCode=" + _countryCode;

	// indicate progress
	progress.show($('.paymentmethodform'));

	$(".paymentmethodform").load(_url, function () {
		$(".paymentmethodform").fadeIn("fast");
		initPaymentMethodSelection();

		$ccNo = $("input[name$='paymentMethods_creditCard_number']");
		$ccOwner = $("input[name$='paymentMethods_creditCard_owner']");
		$ccType = $("select[name$='paymentMethods_creditCard_type']");
		$ccNo = $("input[name$='paymentMethods_creditCard_number']");
		$ccMonth = $("input[name$='paymentMethods_creditCard_month']");
		$ccYear = $("input[name$='paymentMethods_creditCard_year']");
		$ccCVV = $("input[name$='paymentMethods_creditCard_cvn']");
		$ccMonthYear = $(ccMonthYearSelector);

		if ($ccNo.length > 0) {
			initCreditCartForm();
		}

		inputs.init();

		if ($.trim($(".redemption.giftcert").html()).length > 0) {
			$(".gift-cert-form").fadeIn(500);
		}

		// Trigger payment methods updated event so that plugged in
		// components can re-initialize themselves.
		$("#paymentmethods", document).trigger(PAYMENT_METHODS_UPDATED_EVENT);
	});
};

/**
 * @function openAddressAccordions()
 * @description
 */
var openAddressAccordions = function (accordionClosed) {
	if (accordionClosed.length > 0) {
		accordionClosed.each(function () {
			accordion.openAccordion(accordionClosed);
		});

		$(".shipping-as-billing input").attr('checked', false);
	}
};

/**
 * @function checkShippingAsBilling()
 * @description
 */
var checkShippingAsBilling = function () {

	// Clean up postal based on country
	var _countryCode = $country.val();
	var _postalMasks = $(".checkout-billing").data("postalmasking");

	address.changePostalCode(_countryCode, _postalMasks);

	// assume no state option okay
	if ($state.val() === null) {
		$state.data("shipping", null);
	}

	if ($firstName.val() != $firstName.data("shipping") ||
		$lastName.val() != $lastName.data("shipping") ||
		$address1.val() != $address1.data("shipping") ||
		$zip.val() != $zip.data("shipping") ||
		$city.val() != $city.data("shipping") ||
		$country.val() != $country.data("shipping") ||
		$state.val() != $state.data("shipping")
	) {
		openAddressAccordions($('.billing-address .accordion-closed'));
	} else {
		fillBillingWithShipping();
	}
};

/**
 * @function fillBillingWithShipping()
 * @description
 */
var fillBillingWithShipping = function () {
	// changing Country select, re-populate the Postal Code mask
	address.changeFormSelection($country[0], $country.data("shipping"));
	var _countryCode1 = $country.val();
	var _postalMasks = $(".checkout-billing").data("postalmasking");
	address.changePostalCode(_countryCode1, _postalMasks);

	$firstName.val($firstName.data("shipping"));
	$lastName.val($lastName.data("shipping"));
	$companyName.val($companyName.data("shipping"));
	$address1.val($address1.data("shipping"));
	$address2.val($address2.data("shipping"));
	$city.val($city.data("shipping"));
	$zip.val($zip.data("shipping"));
	$phone.val($phone.data("shipping"));

	address.updateState($country.data("shipping"), $state.data("shipping")); // Re-populate State base on Country

	formPrepare.validateBillingForm(true);

	$(".shipping-as-billing input").attr('checked', true);
};

/**
 * Show the authorizing spinner
 *
 * @param {String} message to show next to spinner, default to an authorizing message
 */
var showAuthorizingSpinner = function (message) {
	var formattedMessage = message || Resources.CHECKOUT_AUTHORIZING;
	$('.confirm-payment-cta').hide();
	$('.confirm-payment-indicator1').css('display', 'block');
	$(".confirm-payment").append("<div class='confirm-payment-message'><div class='messaging'>" + formattedMessage + "</div><div class='processing' style='display: none;'>" + Resources.CHECKOUT_PROCESSING + " <span id='timer'></span></div></div>");

	// Perpetual upward counting clock
	// every 1 second grab the datestamp and update the DOM
	// with the difference from the start time.
	// formatted to be 2 digit
	function startProcessingTimer() {
		var seconds = 0;
		var minutes = 0;
		var outSeconds = 0;
		var outMinutes = 0;
		var timeStart = Date.now();
		var timeNow = Date.now();

		return setInterval(function () {
			timeNow = Date.now();
			seconds = Math.floor((timeNow - timeStart) / 1000 % 60);
			minutes = Math.floor((timeNow - timeStart) / 1000 / 60);
			outSeconds = String(seconds).padStart(2, "0");
			outMinutes = String(minutes).padStart(2, "0");

			$('#timer').html(outMinutes + ":" + outSeconds);

			// Wait till 6 seconds to display output
			if (seconds >= 6) {
				$('#timer').parent().show();
			}
		}, 1000);
	}

	return startProcessingTimer();
};

function hideAuthorizingSpinner(interval) {
	$('.confirm-payment-indicator1').css('display', 'none');
	$(".confirm-payment").find(".confirm-payment-message").remove();
	$('.confirm-payment-cta').show();
	if (interval) {
		clearInterval(interval);
	}
}

/**
 * @function initializeInvoice()
 * @description Initialize Invoice functionalities
 */
var initializeInvoice = function () {
	if ($invoiceWrapper.length) {
		var $invoiceContainers = $invoiceWrapper.find('[class$=-invoice-container]');
		var $wantInvoice = $invoiceWrapper.find('input[name$="_wantInvoice"]');
		var $invoiceTypeRadios = $invoiceWrapper.find('input[name$="_invoiceType"]');
		var $fiscalCode = $invoiceContainers.find('input[name$=_fiscalCode]');
		var $businessName = $invoiceContainers.find('input[name$=_businessName]');
		var $vatNumber = $invoiceContainers.find('input[name$=_vatNumber]');
		var $pecEmail = $invoiceContainers.find('input[name$=_pecEmail]');
		var $recipientCode = $invoiceContainers.find('input[name$=_recipientCode]');
		var $errorInvoicePECorRecipient = $invoiceWrapper.find('#pec-or-recipient-error');
		var invoiceFields = [$fiscalCode, $businessName, $vatNumber, $pecEmail, $recipientCode];

		$fiscalCode.on('input', function () {
			this.value = this.value.toUpperCase();
		});
		$recipientCode.on('input', function () {
			this.value = this.value.toUpperCase();
		});

		// Define validator for requiring PEC Email or Recipient Code
		var validateInvoicePECorRecipient = function (buynowContinue) {
			if ($wantInvoice.is(':checked') && $pecEmail.length && $recipientCode.length ) {
				if ($invoiceTypeRadios.filter('[value="company"]').is(':checked')) {
					if (!$pecEmail.val() && !$recipientCode.val()) {
						$errorInvoicePECorRecipient.show();

						// Focus on PEC Email and Recipient Code fields while doesn't have another error field
						if (buynowContinue) {
							$pecEmail.focus();
						}

						return false;
					}
					$errorInvoicePECorRecipient.hide();
					return true;
				}
			}

			return true;
		};

		var clearInvoiceForm = function () {
			invoiceFields.forEach(function (invoiceField) {
				clearField(invoiceField);
			});

			util.checkInputVal(invoiceFields);
		};

		var triggerValidatePECorRecipient = function () {
			var $elm = $(this);
			validateInvoicePECorRecipient();
			$elm.valid() && $elm.parent(".field-error").removeClass("field-error").find("span.field-error").remove();
		};

		// init accordion for toggle I want an Invoice function
		accordion.attach('#billing-invoice-wrapper', '.want-an-invoice input', '.billing-invoice-form', $wantInvoice.is(':checked') ? 'open' : 'closed', clearInvoiceForm);
		// Show/Hide the Invoice form to enable/disable Validator base on Accordion behavior
		$invoiceWrapper.find('.billing-invoice-form').on('accordionOpening', function () {
			$(this).show();
		}).on('accordionClosed', function () {
			$(this).hide();
		}).trigger($wantInvoice.is(':checked') ? 'accordionOpening' : 'accordionClosed');

		// toggle invoice type form section
		var changeInvoiceType = function (invoiceType) {
			var showing = $invoiceContainers.filter('.' + invoiceType + '-invoice-container').slideDown(200);
			$invoiceContainers.not(showing).slideUp(200);
		};

		changeInvoiceType($invoiceTypeRadios.filter(':checked').val()); // Need to call separated from click event to prevent changing checked options

		$invoiceTypeRadios.on('click.invoice', function () {
			changeInvoiceType($invoiceTypeRadios.filter(':checked').val());
			$invoiceTypeRadios.val() == 'personal' && $errorInvoicePECorRecipient.hide();
		});

		// Remove required validator for PEC Email field
		$pecEmail.length && $pecEmail.rules('remove', 'required');

		// Trigger validator for PEC EMail and Recipient code when focus out
		$pecEmail.on('blur.invoice', triggerValidatePECorRecipient);
		$recipientCode.on('blur.invoice', triggerValidatePECorRecipient);
	}
};

/**
 * @function initializeDOM()
 * @description loads billing address, Gift Certificates, Coupon and Payment
 *              methods
 */
var initializeDOM = function () {


	var $billingContainer = $('.checkout-billing');
	var _giftCertOnly = $billingContainer.data("giftcertonly");
	var _siteID = $billingContainer.data("siteid");
	var _hasShippingAddress = !!$billingContainer.find('.use-shipping-as-billing').length;

	// Format fields for better UI
	util.updateInputTypes($email, 'email');
	util.updateInputTypes($phone, 'tel');
	util.updateInputTypes($ccNo, 'tel');
	util.updateInputTypes($ccCVV, 'tel');

	// Shipping Address Block accordion
	accordion.attach('.pt_checkout .shipping-address-block', '.trigger', '.details-more', 'closed', toggleLabel);

	function toggleLabel() {
		$('.pt_checkout .shipping-address-block').find('.more, .less').toggle();
	}

	// Puts the billing form in an accordion
	_hasShippingAddress ? accordion.attach('.billing-address', '.shipping-as-billing input', '.billing-form', 'closed') : '';

	// Gift cert accordion
	if ($(".gift-cert").length > 0) {
		util.updateInputTypes($('input[name$="_giftCertPin"]'), 'tel');
	}

	// If this is a gift card only order, shipping step is skipped and will not
	// have a pre-fill.
	if (_giftCertOnly) {
		if ($state.val() == "" && $country.val() == "") {
			var _shipToCountry = $(".checkout-billing").data("shiptocountry");
			$country.val(_shipToCountry);
			address.updateState(_shipToCountry, "");
		}
	} else {
		// update state label upon entering page
		if (_siteID == "EU") {
			address.updateState($country.val(), $state.val());
		}
	}

	// Check to see if all required billing address fields are filled out, if
	// not, show the form.
	checkShippingAsBilling();

	// Clear CCN & CVN on page if there was a submission error.
	$ccNo.val("");
	$ccCVV.val("");

	// init credit card form for pattern matching and card type check
	if ($ccNo.length > 0) {
		initCreditCartForm();
	}

	formPrepare.init({
		formSelector: 'form[id$="_billing"]',
		continueSelector: '[name$="_billing_save"]'
	});

	initPromoWhy();

	checkout.refreshTooltips();

	accordion.attach('.promo-code-entry', '.plus-minus-link', '.promo-and-gift-content', 'closed');
	var $term = $("#termsAndConditionsAgreement");
	$term.length && $term.rules('add', {checkboxRequired: true});
};

function initCreditCartForm() {
	ccTypePattern.init($ccNo, $ccType);

	cleaveMonthYear = new Cleave(ccMonthYearSelector, {
		date: true,
		datePattern: ['m', 'y'],
		onValueChanged: function (e) {
			var rawValue = e.target.rawValue;
			if (rawValue.length == 4) {
				$ccMonth.val(Number(rawValue.substring(0, 2)));
				$ccYear.val(Number('20' + rawValue.substring(2)));
			}
		}
	});
	disableCreditCardSelectionAutofill();
}

/**
 * @function disableCreditCardSelectionAutofill()
 * @description Prevent brower autofill credit card selection, since it would overide the browser credit card
 */
function disableCreditCardSelectionAutofill() {
	var $ccList = $("select[name$='_creditCardList']");
	if ($ccList && $ccList.length) {
		$ccList.attr('readonly', true).on('focus', function () {
			$ccList.attr('readonly', false);
		}).on('blur', function () {
			$ccList.attr('readonly', true);
		});
	}
}

/**
 * @function initializeEvents()
 * @description
 */
var initializeEvents = function () {
	// ---- Apply a PromoCode
	$(".checkout-billing").on("click", ".promo-code-entry button.apply-promo-code", function (e) {
		e.preventDefault();

		var emptyCheck = true;
		var $input = $(this).closest(".promocodes").find("input.coupon-input");
		var _promoCode = $input.val();
		var _href = $input.data("applypromo");
		var _refresh = $(this).closest(".promocodes").data("refresh");

		if (_promoCode) {
			_href += "?promocode=" + _promoCode;
			_refresh += "?promocode=" + _promoCode;
			emptyCheck = false;
		}

		promoCode(_href, _refresh, emptyCheck);
	});

	// Apply a PromoCode on Enter key
	$(".checkout-billing").on("keyup", ".promo-code-entry input.coupon-input", function (e) {
		// override enter key for coupon code entry
		if (e.keyCode == 13) {
			e.preventDefault();

			var emptyCheck = true;
			var _promoCode = $(this).val();
			var _href = $(this).data("applypromo");
			var _refresh = $(this).closest(".promocodes").data("refresh");

			if (_promoCode) {
				_href += "?promocode=" + _promoCode;
				_refresh += "?promocode=" + _promoCode;
				emptyCheck = false;
			}

			promoCode(_href, _refresh, emptyCheck);
		}

	});

	// ---- Remove a PromoCode
	$(".checkout-billing").on("click", ".coupon-row a.remove-coupon", function (e) {
		e.preventDefault();

		var _href = $(this).attr("href");
		// Update order summary (nested on purpose)
		progress.show($('.checkout-summary'));
		$.ajax(_href).done(function (summaryData) {
			if (summaryData) {
				updateCheckoutSummaryHtml(summaryData);
				updatePaymentMethods();
			} else {
				progress.hide($('.checkout-summary'));
			}
		});

		$(this).closest(".coupon-row").remove();

	});

	// ---- select credit card from list
	$(".checkout-billing").on("change", "select[name$='paymentMethods_creditCardList']", function () {
		var _cardUUID = $(this).val();
		if (_cardUUID) {
			populateCreditCardForm(_cardUUID);
		}
	});

	// ---- Billing address same as Shipping Toggle
	$(".checkout-billing").on("change", ".shipping-as-billing input", function () {
		if (!$(this).is(':checked')) {
			clearAddressForm();

			// if addresslist is present, might need to repopulate
			if (!$('.addresslist input:checked').is('#shipping-address-new')) {
				// Repopulate inputs
				var _addressID = $('.addresslist input:checked').val();
				if (_addressID) {
					populateAddressForm(_addressID);
				}
			}
		} else {
			fillBillingWithShipping();
		}
	});

	// ---- select address from list
	$(".checkout-billing").on("change", ".addresslist input", function (e) {
		e.preventDefault();

		// Toggle the address form
		address.showAddressForm(this, '#shipping-address-new');

		if ($(this).is('#shipping-address-new')) {
			clearAddressForm();
		} else {
			// Repopulate inputs
			var _addressID = $(this).val();
			if (_addressID) {
				populateAddressForm(_addressID);
			}
		}
	});

	// ---- Payment method change
	$(".checkout-billing").on("click", "input[name$='_paymentMethods_selectedPaymentMethodID']", function () {
		var _selectedID = $(this).val();
		changePaymentMethod(_selectedID, true, $(this));
	});

	// ---- Gift Certificates
	if ($(".gift-cert").length > 0) {

		// bind and handle gift cert redemption and removal
		$(".checkout-billing").on("click", "#add-giftcert", function (e) {
			e.preventDefault();
			processGiftCard();
		});

		// Apply a gift certificate on Enter key
		$(".checkout-billing").on("keyup", ".gift-cert-form input", function (e) {
			if (e.keyCode == 13) {
				e.preventDefault();
				processGiftCard();
			}
		});

		$(".checkout-billing").on("click", "#check-giftcert", function (e) {
			e.preventDefault();
			processGiftCardCheck();
		});

		$(".checkout-billing").on("click", ".giftcert-item .remove", function (e) {
			e.preventDefault();
			var giftCertNumber = $(e.target).parent('.remove').data('giftcert-number');
			removeGiftCard(giftCertNumber);
		});

	}

	// ---- handle whole form submit (bind click to continue checkout button)
	// append form fields of current payment form to this submit
	// in order to validate the payment method form inputs too
	$("button[name$='billing_save']").on("click", function (e) {
		e.preventDefault();
		var $this = $(this);

		// clear all space before submit the card number
		if ($ccNo.length > 0) {
			$ccNo.val($ccNo.val().replace(/ /g, ''));
		}

		formPrepare.validateBillingForm();

		var _buynowContinue = false;
		var $selectedPaymentMethod = $("input[name$='_selectedPaymentMethodID']:checked");
		var $preSelectedPaymentMethod = $("input[name$='_selectedPaymentMethodID'][type='hidden']");
		var _selectedPaymentMethodID = $selectedPaymentMethod.val();

		if ($("#noPaymentNeeded").length > 0 && $(".giftcert-pi").length > 0) {

			// as a safety precaution, uncheck any existing payment methods
			$("input[name$='_selectedPaymentMethodID']").filter(":checked").removeAttr("checked");

			// add selected radio button with gift card payment method
			$("<input/>").attr({
				name: $("input[name$='_selectedPaymentMethodID']").first().attr("name"),
				type: "radio",
				checked: "checked",
				style: "display:none",
				value: "GIFT_CERTIFICATE"
			}).appendTo($billingForm);

			_selectedPaymentMethodID = "GIFT_CERTIFICATE";
		} else if ($preSelectedPaymentMethod.length === 0) {
			// Set Payment Method
			$billingForm.append("<input type='hidden' name='" + $("input[name$='_selectedPaymentMethodID']").attr("name") + "' value='" + _selectedPaymentMethodID + "' />");
		}

		// Hide terms of service error if visible
		$("#termsofuse-agree-chk-error").hide();

		if ($billingForm.valid()) {

			var authorizingSpinnerMessage = null;

			// Express checkout (e.g. PayPal Express), where customers needed to be redirected back to the billing page.
			var preSelectedPaymentMethodId = $("input[name$='_selectedPaymentMethodID'][type='hidden']").val();
			if (!_selectedPaymentMethodID && preSelectedPaymentMethodId && $("#PaypalOrigination").val() == "ExpressCheckout") {
				_selectedPaymentMethodID = preSelectedPaymentMethodId;
			}

			// Push GTM Checkout Action Data
			gtmTracking.pushCheckoutAction($this, {checkout_option: _selectedPaymentMethodID});

			if (_selectedPaymentMethodID == "CREDIT_CARD") {
				// append inputs of payment method form as hidden elements to main form (all inputs except checkboxes)
				$('#PaymentMethod_' + _selectedPaymentMethodID + ' form :input').filter(":not(:checkbox)").each(function () {
					var _input = $(this);
					$billingForm.append("<input type='hidden' name='" + _input.attr("name") + "' value='" + _input.val() + "' />");
				});

				// Saved Credit Card (Registered Users Only)
				$billingForm.append("<input type='hidden' name='creditCardUUID' value='" + $("select[name$='paymentMethods_creditCardList']").val() + "' />");

				// checkboxes are only appended if checked
				$('#PaymentMethod_' + _selectedPaymentMethodID + ' form :checkbox:checked').each(function () {
					var _input = $(this);
					$billingForm.append("<input type='hidden' name='" + _input.attr("name") + "' value='" + _input.val() + "' />");
				});

				_buynowContinue = true;

			} else if (_selectedPaymentMethodID == "AFTERPAY") {
				authorizingSpinnerMessage = Resources.CONTINUE_TO_AFTERPAY;
				_buynowContinue = true;
			} else if (_selectedPaymentMethodID == "BANK_TRANSFER") {
				_buynowContinue = true;
			} else if (_selectedPaymentMethodID == "PayPal" || _selectedPaymentMethodID == "paypal") {
				_buynowContinue = true;
			} else if (_selectedPaymentMethodID == "GIFT_CERTIFICATE") {
				_buynowContinue = true;
			} else if ($selectedPaymentMethod.data("brandcode").indexOf('klarna') > -1) {
				authorizingSpinnerMessage = Resources.SENDING_TO_KLARNA;
				_buynowContinue = true;
			} else {
				authorizingSpinnerMessage = Resources.SENDING_TO_BANK;
				_buynowContinue = true;
			}
		}

		if ($invoiceWrapper.length) {
			_buynowContinue = (validateInvoicePECorRecipient ? validateInvoicePECorRecipient(_buynowContinue) : true) && _buynowContinue;
		}

		// submit the main form
		if (_buynowContinue) {
			// Submit the form after all the validation known to the billing page.
			// Form submission can be cancelled by any listeners. If any listeners need
			// to veto form submission, ensure to call 'stopSubmit' on the given data
			// to stop the authorization spinner.
			var spinnerInterval = showAuthorizingSpinner(authorizingSpinnerMessage);
			$billingForm.trigger('submit', {
				stopSubmit: function () {
					hideAuthorizingSpinner(spinnerInterval);
				}
			});
		} else {
			// Put buy now or continue button back.
			hideAuthorizingSpinner();

			// Sniffing for errors
			var errorField = $billingForm.find("div.field-error input").first();

			// Error in an accordion
			openAddressAccordions(errorField.parents('.accordion-closed'));
			errorField.focus();
		}
	});

	// ---- Autofilled credit card clear
	$(".checkout-billing").on("click", ".clear-selected-card", function (e) {
		e.preventDefault();

		var $ccList = $("select[name$='_creditCardList']");
		$ccList.val("");
		$ccOwner.val("").attr('data-empty', true).addClass("required").removeClass("disabled");
		$ccNo.val("").attr('data-empty', true).addClass("required").removeClass("disabled");
		$ccMonthYear.attr('data-empty', true).addClass("required").removeClass("disabled");

		cleaveMonthYear.setRawValue('');
		$ccMonth.val("");
		$ccYear.val("");

		$ccOwner.prop("disabled", false);
		$ccNo.prop("disabled", false);
		$ccMonthYear.prop("disabled", false);

		$(this).hide();
	});

	initPaymentMethodSelectionEvent();
};

exports.init = function () {
	initializeDOM();
	initializeEvents();
	initializeInvoice();
	// Select a previously selected payment with a delay so that other components' event listeners
	// have a chance to register for payment selection event.
	setTimeout(initPaymentMethodSelection, 50);
};

exports.setPaymentSection = setPaymentSection;
exports.updatePaymentMethods = updatePaymentMethods;
