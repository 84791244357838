'use strict';

var util = require('../components/util');
var cardValidator = require('card-validator');

var regex = {
	phone: {
		us: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
		ca: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
		intl: /^[0-9 _./\\+()-]*$/
	},
	postal: {
		us: /^\d{5}(-\d{4})?$/,
		ca: /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1}[- ]*\d{1}[A-Za-z]{1}\d{1}$/,
		fr: /^\d{5}$/,
		de: /^\d{5}$/,
		es: /^\d{5}$/,
		au: /^\d{4}$/,
		nz: /^\d{4}$/,
		uk: /^[\w\s]+$/,
		ua: /^\d{5}$/,
		ru: /^[0-9]{6}/,
		za: /^\d{4}$/,
		be: /^\d{4}$/,
		lu: /^\d{4}$/,
		nl: /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/,
		at: /^\d{4}$/,
		dk: /^\d{4}$/,
		se: /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
		fi: /^\d{5}$/,
		ee: /^\d{5}$/,
		lv: /^(LV-)?[0-9]{4}$/,
		cz: /^\d{3}[ ]?\d{2}$/,
		pl: /^\d{2}[- ]{0,1}\d{3}$/,
		ch: /^(CH-)?[0-9]{4}$/,
		lt: /^(LT-)?[0-9]{5}$/,
		sk: /^((SK-)?[0-9]{5})|(([0-9]{5})|([0-9]{3}[ ]{0,1}[0-9]{2}))$/,
		si: /^(SI-)?[0-9]{4}$/,
		ie: /.*/,
		it: /^\d{5}$/,
		ad: /^(ad)\d{3}$/i, // Andorra
		ae: /^\d{5}$/, // United Arab Emirates
		af: /^\d{4}$/, // Afghanistan
		al: /^\d{4}$/, // Albania
		am: /^\d{4}$/, // Armenia
		ao: /^\d{4}$/, // Angola
		ar: /^\d{4}$/, // Argentina
		az: /^\d{4}$/, // Azerbaijan
		ba: /^\d{5}$/, // Bosnia and Herzegovina
		bd: /^\d{4}$/, // Bangladesh
		bf: /^\d{4}$/, // Burkina Faso
		bg: /^\d{4}$/, // Bulgaria
		bh: /^\d{4}$/, // Bahrain
		bi: /^\d{4}$/, // Burundi
		bj: /^\d{4}$/, // Benin
		bn: /^[A-Z]{2}\d{4}$/, // Brunei
		bo: /^\d{4}$/, // Bolivia
		br: /^\d{8}$/, // Brazil
		bt: /^\d{5}$/, // Bhutan
		bw: /^\d{4}$/, // Botswana
		by: /^\d{6}$/, // Belarus
		bz: /^\d{4}$/, // Belize
		cf: /^\d{4}$/, // Central African Republic
		cg: /^\d{4}$/, // Congo
		cl: /^\d{7}$/, // Chile
		cm: /^\d{4}$/, // Cameroon
		cn: /^\d{6}$/, // China
		co: /^\d{6}$/, // Colombia
		cr: /^\d{5}$/, // Costa Rica
		cu: /^\d{5}$/, // Cuba
		cv: /^\d{4}$/, // Cape Verde
		cy: /^\d{4}$/, // Cyprus
		dj: /^\d{4}$/, // Djibouti
		dm: /^\d{4}$/, // Dominica
		do: /^\d{5}$/, // Dominican Republic
		dz: /^\d{5}$/, // Algeria
		ec: /^\d{6}$/, // Ecuador
		eg: /^\d{5}$/, // Egypt
		er: /^\d{4}$/, // Eritrea
		et: /^\d{4}$/, // Ethiopia
		fj: /^\d{4}$/, // Fiji
		fm: /^\d{5}$/, // Micronesia
		ga: /^\d{4}$/, // Gabon
		ge: /^\d{4}$/, // Georgia
		gh: /^\d{4}$/, // Ghana
		gl: /^\d{4}$/, // Greenland
		gm: /^\d{4}$/, // Gambia
		gn: /^\d{4}$/, // Guinea
		gq: /^\d{4}$/, // Equatorial Guinea
		gr: /^\d{5}$/, // Greece
		gt: /^\d{5}$/, // Guatemala
		gw: /^\d{4}$/, // Guinea-Bissau
		gy: /^\d{4}$/, // Guyana
		hk: /^\d{4}$/, // Hong Kong SAR
		hn: /^\d{5}$/, // Honduras
		hr: /^\d{5}$/, // Croatia
		ht: /^\d{4}$/, // Haiti
		id: /^\d{5}$/, // Indonesia
		il: /^\d{5}$/, // Israel
		in: /^\d{6}$/, // India
		iq: /^\d{5}$/, // Iraq
		ir: /^\d{10}$/, // Iran
		is: /^\d{3}$/, // Iceland
		jm: /^\d{4}$/, // Jamaica
		jo: /^\d{5}$/, // Jordan
		jp: /^\d{7}(-\d{4})?$/, // Japan
		ke: /^\d{5}$/, // Kenya
		kg: /^\d{6}$/, // Kyrgyzstan
		kh: /^\d{5}$/, // Cambodia
		ki: /^\d{4}$/, // Kiribati
		km: /^\d{4}$/, // Comoros
		kn: /^\d{4}$/, // Saint Kitts and Nevis
		kr: /^\d{5}$/, // South Korea
		kw: /^\d{5}$/, // Kuwait
		la: /^\d{4}$/, // Laos
		lb: /^\d{4}$/, // Lebanon
		lc: /^\d{4}$/, // Saint Lucia
		li: /^\d{4}$/, // Liechtenstein
		lr: /^\d{4}$/, // Liberia
		ls: /^\d{3}$/, // Lesotho
		ly: /^\d{4}$/, // Libya
		ma: /^\d{5}$/, // Morocco
		mc: /^\d{5}$/, // Monaco
		md: /^\d{4}$/, // Moldova
		me: /^\d{5}$/, // Montenegro
		mg: /^\d{3}$/, // Madagascar
		mh: /^\d{5}$/, // Marshall Islands
		mk: /^\d{4}$/, // North Macedonia
		ml: /^\d{4}$/, // Mali
		mm: /^\d{5}$/, // Myanmar
		mn: /^\d{5}$/, // Mongolia
		mr: /^\d{4}$/, // Mauritania
		mt: /^[A-Z]{3}\d{4}$/, // Malta
		mu: /^\d{4}$/, // Mauritius
		mv: /^\d{5}$/, // Maldives
		mw: /^\d{4}$/, // Malawi
		mx: /^\d{5}$/, // Mexico
		my: /^\d{5}$/, // Malaysia
		mz: /^\d{4}$/, // Mozambique
		na: /^\d{5}$/, // Namibia
		ne: /^\d{4}$/, // Niger
		ng: /^\d{6}$/, // Nigeria
		ni: /^\d{5}$/, // Nicaragua
		no: /^\d{4}$/, // Norway
		np: /^\d{5}$/, // Nepal
		nr: /^\d{4}$/, // Nauru
		om: /^\d{3}$/, // Oman
		pa: /^\d{6}$/, // Panama
		pe: /^\d{5}$/, // Peru
		pg: /^\d{4}$/, // Papua New Guinea
		ph: /^\d{4}$/, // Philippines
		pk: /^\d{5}$/, // Pakistan
		ps: /^\d{4}$/, // Palestine
		pt: /^\d{7}$/, // Portugal
		pw: /^\d{5}$/, // Palau
		py: /^\d{4}$/, // Paraguay
		qa: /^\d{4}$/, // Qatar
		rw: /^\d{4}$/, // Rwanda
		sg: /^\d{6}$/, // Singapore
		sv: /^\d{4}$/, // El Salvador
		sz: /^\d{4}$/, // Eswatini
		td: /^\d{4}$/, // Chad
		tl: /^\d{4}$/, // East Timor
		tw: /^\d{5}$/, // Taiwan
		vc: /^\d{4}$/ // Saint Vincent and the Grenadines
	},
	email: /^(?=.{6,}$)([-+._a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-+._][a-zA-Z0-9]+)*\.([a-zA-Z0-9]+([-+._][a-zA-Z0-9]+)*){2,}$/,
	creditcard: {
		amex: /^(?:3[47][0-9]{13})$/,
		visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
		master: /^(?:[25][1-5][0-9]{14})$/,
		discover: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/
	},
	city: {
		all: /^[^0-9()]+$/,
		ie: /^[\w\-\s]+$/
	},
	cvv: /^\d{3,4}$/,
	addresspal: /(^|\s)(ap|address\s+pal|addresspal)($|\s|,|\.)/i,
	alphanumeric: /^[a-zA-Z0-9]+$/,
	// Regex detection of CJK by unicode, https://stackoverflow.com/a/21113538
	// Ideal solution would be '/\p{Script=Katakana}|\p{Script=Hiragana}|\p{Script=Hangul}|\p{Script=Bopomofo}/u' , however it is a new standard which is not supported by our backend javascript. To keep FE and BE consistent, we decide to use following solution.
	cjk: /[\u4E00-\u9FCC\u3040-\u30ff\u3400-\u4DB5\uff00-\uffef\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]|[\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF]/
};

/**
 * @function runValidation()
 * @description
 */
var runValidation = function (element) {

	if ($(element).hasClass("required") || $(element).hasClass("validate")) {
		$(element).closest(".value").find("span.field-error").removeClass('field-error').text('');
		$(element).removeClass("field-success").removeClass("field-error");
		$(element.parentElement).removeClass("field-success").removeClass("field-error");

		if (!this.checkable(element)) {
			this.element(element);

			if (this.errorList.length > 0) {
				// Do Nothing
			} else {
				$(element.parentElement).addClass("field-success");
			}
		}
	}
};

// global form validator settings
var settings = {
	errorClass: 'field-error',
	errorElement: 'span',
	onkeyup: function () {
	},
	onfocusout: runValidation,
	showErrors: function (errorMap, errorList) {
		$.each(errorList, function (index, obj) {

			var _message = obj.message;

			var $errorMessage;
			if (obj.element.id) {
				$errorMessage = $(obj.element).closest(".value").find("#" + obj.element.id + '-error');
			}
			// Cleanup
			$errorMessage && $errorMessage.length && $errorMessage.removeClass('field-error').text('');
			$(obj.element).removeClass("field-success").removeClass("field-error");
			$(obj.element.parentElement).removeClass("field-success").removeClass("field-error");

			if ($(obj.element).hasClass("state")) {
				if ($(".country:input").val() == "CA") {
					_message = _message.replace("XXX", Resources.PROVINCE_LABEL);
				}
				if ($(".country:input").val() == "US") {
					_message = _message.replace("XXX", Resources.STATE_LABEL);
				}
			}

			if ($(obj.element).hasClass("postalcode")) {

				if ($(".country:input").val() == "US") {
					_message = _message.replace("XXX", Resources.ZIP_LABEL);
				} else if ($(".country:input").val() == "UK") {
					_message = _message.replace("XXX", Resources.POSTCODE_LABEL);
				} else {
					_message = _message.replace("XXX", Resources.POSTALCODE_LABEL);
				}
			}

			// Display Error for field
			if ($errorMessage && !$errorMessage.length) {
				$("<span id='" + obj.element.id + "-error' class='field-error' role='alert'>" + _message + "</span>").insertAfter(obj.element);
			} else {
				$errorMessage.addClass('field-error').text(_message);
			}

			$(obj.element.parentElement).addClass("field-error");
		});
	}
};

/**
 * @function validatePhone
 * @description Validates a given phone
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
	var $country = $(el).closest('form').find('.country:input');

	if ($country.length > 0) {
		var _countryCode = ($country.val() || '').toLowerCase();

		var _isOptional = this.optional(el);
		var _isValid = false;
		if (_countryCode == "us" || _countryCode == "ca") {
			_isValid = regex.phone[_countryCode].test($.trim(value));
			return _isOptional || _isValid;
		} else {
			_isValid = value.length > 3 && regex.phone.intl.test($.trim(value));
			return _isOptional || _isValid;
		}
	} else {
		return true;
	}
};

/**
 * @function validateCity
 * @description Validates a given city
 * @param {String} value The City which will be validated
 * @param {String} el The input field
 */
var validateCity = function (value, el) {
	var $country = $(el).closest('form').find('.country:input');
	var _countryCode = ($country.val() || '').toLowerCase();

	if (_countryCode != "ie") { // Ireland
		_countryCode = "all";
	}

	var isOptional = this.optional(el);
	var isValid = regex.city[_countryCode].test($.trim(value));
	return isOptional || isValid;
};

/**
 * @function validateOwner
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
	var isValid = regex.notCC.test($.trim(value));
	return isValid;
};

/**
 * @function validateEmail
 * @description Validates a given email
 * @param {String} value The email which will be validated
 * @param {String} el The input field
 */
var validateEmail = function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.email.test($.trim(value));
	return isOptional || isValid;
};

/**
 * @function validatePostalCode
 * @description Validates a given postal code
 * @param {String} value The postal code which will be validated
 * @param {String} el The input field
 */
var validatePostalCode = function (value, el) {
	var $country = $(el).closest('form').find('.country:input');
	var _countryCode = ($country.val() || '').toLowerCase();
	var isOptional = this.optional(el);
	var isValid = regex.postal[_countryCode].test($.trim(value));
	return isOptional || isValid;
};

/**
 * @function validateCardNo
 * @description Validates a given card number and supported type
 * @param {String} value The card number which will be validated
 * @param {String} el The input field
 */
var validateCardNo = function (value, el) {
	var $ccForm = $(el).closest(".creditcardpayment, .creditcard-form");
	var $ccType = $ccForm.find("select[name$='_creditCard_type'], select[name$='_newcreditcard_type']");
	var ccTypeList = $ccType.children().toArray().map(function (o) {
		return $(o).val();
	});
	var numberValidation = cardValidator.number(value.replace(/ /g, ''));
	var matchingType = numberValidation.card && util.matchIBCardType(numberValidation.card.type);
	return ccTypeList.indexOf(matchingType) >= 0 && numberValidation.isValid;
};

/**
 * @function validateExpiration
 * @description Validates a given expiration
 * @param {String} value The expiration which will be validated
 * @param {String} el The input field
 */
var validateExpiration = function (value, el) {
	var _today = new Date();

	// Strip out non numeric values - SFCC puts the number grouping delimiter to year too :(
	var _inputYear = value.replace(/[^\d]/g, '');

	var _inputMonth = $(el).closest("form").find("select.cardmonth").val();
	var _expires = new Date(parseInt(_inputYear), (parseInt(_inputMonth) - 1), _today.getDate() + 1);

	if (_expires >= _today) {
		return true;
	}

	return false;
};

/**
 * @function validateCVV
 * @description Validates a given cvv
 * @param {String} value The cvv which will be validated
 * @param {String} el The input field
 */
var validateCVV = function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.cvv.test($.trim(value));
	return isOptional || isValid;
};

/**
 * @function validatePOBox
 * @description Validates an address line 1 to see if it's a PO Box.
 * @param {String} value The address which will be validated
 * @param {String} el The input field
 */
var validatePOBox = function (value, el) {

	var _allowPOBox = $(el).data("allowpobox");

	// Escape if not applicable
	if (_allowPOBox == null) {
		return true;
	}

	var _poBoxRegex = /\b(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i;
	var _isValid = true;

	// We found a PO Box
	if (_poBoxRegex.test($.trim(value))) {
		// We allow PO Box
		if (_allowPOBox) {
			$(el).trigger("updateShippingMethodList");
			noteInput(el, Resources.NOTE_POBOX, true, "pobox-note");
		} else {
			// We don't allow PO Box
			_isValid = false;
		}
		// We didn't find a PO Box
	} else {
		$(el).trigger("updateShippingMethodList");
		noteInput(el, Resources.NOTE_POBOX, false);
	}

	return _isValid;
};


/**
 * @function validatePackstation
 * @description Validates packstation in address
 * @param {String} value The address which will be validated
 */
var validatePackstation = function (value, el) {
	var _isValid = true;
	var _packstationNameCombined = 'Packstation';
	if (Resources.PACKSTATION_NAME) {
		_packstationNameCombined = _packstationNameCombined + '|' + Resources.PACKSTATION_NAME;
	}

	var _packstationRegex = new RegExp('\\b([0-9]*[,.-]*(' + _packstationNameCombined + ')[,.-]*[0-9]*)\\b', 'i');

	if (!_packstationRegex.test($.trim(value))) {
		$(el).trigger("updateShippingMethodList");
	} else {
		_isValid = false;
	}

	return _isValid;
};

/**
 * @function validateAddressPal
 * @description Validates an address line 1 to see if it's a AddressPal.
 * @param {String} value The address which will be validated
 * @param {String} el The input field
 */
var validateAddressPal = function (value, el) {

	var _allowPOBox = $(el).data("allowpobox");

	// Escape if not applicable
	if (_allowPOBox == null) {
		return true;
	}

	var _isValid = true;

	if (regex.addresspal.test($.trim(value))) {
		// We allow AddressPal
		if (_allowPOBox) {
			$(el).trigger("updateShippingMethodList");

			noteInput(el, Resources.NOTE_ADDRESSPAL, true, "pobox-note");
		} else {
			// We don't allow AddressPal
			_isValid = false;
		}
		// We didn't find a AddressPal
	} else {
		$(el).trigger("updateShippingMethodList");
		noteInput(el, Resources.NOTE_ADDRESSPAL, false);
	}

	return _isValid;
};

/**
 * @function validateAPO
 * @description Validates a city to see if it's a APO/FPO/DPO military address to ship to.
 * @param {String} value The city which will be validated
 * @param {String} el The input field
 */
var validateAPO = function (value, el) {
	var _allowAPO = $(el).data("allowapo");

	// Escape if not applicable
	if (_allowAPO == null) {
		return true;
	}

	var _apoRegex = /(\b(A|D|F)(PO\b))|(^(A)(A|E|P)$)/i;
	var _isValid = true;

	// We found a APO
	if (_apoRegex.test($.trim(value))) {
		// We allow APO
		if (_allowAPO) {
			$(el).trigger("updateShippingMethodList");
			noteInput(el, Resources.NOTE_APO, true, "apo-note");
		} else {
			// We don't allow APO
			_isValid = false;
		}
		// We didn't find an APO
	} else {
		$(el).trigger("updateShippingMethodList");
		noteInput(el, Resources.NOTE_APO, false, "apo-note");
	}

	return _isValid;
};

/**
 * @function validatePasswordMatch
 * @description Validates a password match.
 * @param {String}
 */
var validatePasswordConfirm = function (value, el) {
	var $password = $(el).closest("form").find("input.password");

	if ($password.length > 0) {
		if ($password.val() == value) {
			return true;
		}
	}

	return false;
};

/**
 * @function validateAlphanumeric
 * @description Validates a given alphanumeric
 * @param {String} value The string which will be validated
 * @param {String} el The input field
 */
var validateAlphanumeric = function (value, el) {
	var isOptional = this.optional(el);
	var isValid = regex.alphanumeric.test($.trim(value));
	return isOptional || isValid;
};

/**
 * @function validateCharsRange
 * @description Validates supported characters
 * @param {String} value The text string which will be validated
 * @param {String} el The input field
 */
var validateCharsRange = function (value, el) {
	var isValid = !regex.cjk.test($.trim(value));
	return isValid;
};

/**
 * @function noteInput
 * @description adds a note msg to an input (vs a invalid msg)
 * @param {String} el The input field
 * @param {String} resource The resource msg to use
 * @param {String} action boolian The action to take (show/hide), defaults to true = show
 * @param {String} id Optional A unique identifier to this note
 */
function noteInput(el, msg, action, id) {
	action = typeof action !== 'undefined' ? action : true;

	// -- Option to remove the note
	if (!action) {
		// remove this unique id
		if (id !== 'undefined' && $('#' + id).length > 0) {
			$('#' + id).remove();
		}
		// Remove following note
		$(el).next('.field-note').remove();
		return;
	}

	// -- There is already a note
	if ($(el).next('.field-note').length > 0) {
		return;
	}

	// This unique note exists
	if (id !== 'undefined' && $('#' + id).length > 0) {
		return;
	} else {
		$(el).after('<div class="field-note" id="' + id + '">' + msg + '</div>');
		return;
	}
}

/**
 * ----------
 * Validator Plugin Configuration Follows
 * ----------
 */
$.validator.setDefaults(settings);
$.validator.addMethod("passwordconfirm", validatePasswordConfirm, Resources.INVALID_PASSWORD_CONFIRM);
$.validator.addMethod("pobox", validatePOBox, Resources.INVALID_POBOX);
$.validator.addMethod("addresspal", validateAddressPal, Resources.INVALID_ADDRESSPAL);
$.validator.addMethod("packstation", validatePackstation, Resources.INVALID_PACKSTATION);
$.validator.addMethod("apo", validateAPO, Resources.INVALID_APO);
$.validator.addMethod("postalcode", validatePostalCode, Resources.INVALID_POSTALCODE);
$.validator.addMethod("email", validateEmail, Resources.INVALID_EMAIL);
$.validator.addMethod("phone", validatePhone, Resources.INVALID_PHONE);
$.validator.addMethod("mobile", validatePhone, Resources.INVALID_PHONE);
$.validator.addMethod("owner", validateOwner, Resources.INVALID_OWNER);
$.validator.addMethod("city", validateCity, Resources.INVALID_CITY);
$.validator.addMethod("ccnumber", validateCardNo, function (value, el) {
	var numberValidation = cardValidator.number(el.value.replace(/ /g, ''));
	// if the cc number itself is valid, but validation fail, it means the card type is not supported
	// which has its own real time hint message
	return numberValidation.isValid ? Resources.VALIDATE_CARDTYPE : Resources.VALIDATE_CREDITCARD;
});
$.validator.addMethod("cardcvn", validateCVV, Resources.INVALID_CVV);
$.validator.addMethod("cardyear", validateExpiration, Resources.INVALID_EXPIRATION);
$.validator.addMethod("alphanumeric", validateAlphanumeric, Resources.INVALID_ALPHANUMERIC);
$.validator.addMethod("charsrange", validateCharsRange, Resources.INVALID_CHARACTERS);

$.validator.addMethod("no-semicolon", function(value) {
	return value.indexOf(';') < 0;
}, Resources.INVALID_SEMICOLON);

$.validator.addMethod("birth-monthdate", function (value, element, params) {
	var monthVal = $('select.' + params[0]).val();
	var dateVal = $('select.' + params[1]).val();
	if (!monthVal.length) {
		return true;
	}
	var month = Number(monthVal);
	var date = Number(dateVal);
	var isValid = true;

	if (isNaN(date) || isNaN(month)) {
		isValid = false;
	} else if (date < 1 || date > 31) {
		isValid = false;
	} else if (month < 1 || month > 12) {
		isValid = false;
	} else if ([4, 6, 9, 11].indexOf(month) >= 0 && date == 31) {
		isValid = false;
	} else if (month == 2 && date > 29) {
		isValid = false;
	}

	return isValid;
}, Resources.VALIDATE_DATE);

$.validator.addMethod("month-and-year", function (value, el) {
	var isOptional = this.optional(el);
	var isValid = value.length == 5;
	return isOptional || isValid;
}, Resources.VALIDATE_DATE);

$.validator.addMethod("future-month-year", function (value, el) {
	var isOptional = this.optional(el);

	var month = value.substring(0, 2);
	var year = value.substring(3);

	var today = new Date();
	var expires = new Date(parseInt('20' + year), (parseInt(month) - 1), today.getDate() + 1);

	return isOptional || (expires >= today);
}, Resources.INVALID_EXPIRATION);

$.validator.addMethod("password", function (value) {

	if (value.length >= 6) {
		return true;
	}

	return false;

}, function () {
	return Resources.VALIDATE_PASSMINLENGTH.replace("{0}", "6");
});


/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
	var $pa = $(".purchase-amount-other");
	var _min = $pa.data("minamount");
	var _max = $pa.data("maxamount");

	// Replace all non-digit characters with a blank space.
	var _value = value.replace(/[^\d]/g, "");

	var isOptional = this.optional(el);
	var isValid = ((parseFloat(_value) >= parseFloat(_min)) && (parseFloat(_value) <= parseFloat(_max)));
	return isOptional || isValid;
}, function () {
	var $pa = $(".purchase-amount-other");
	var _min = $pa.data("minamount");
	var _max = $pa.data("maxamount");

	return Resources.GIFT_CERT_AMOUNT_INVALID.replace("{0}", _min).replace("{1}", _max);
});

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
	if ($.trim(value).length === 0) {
		return true;
	}
	return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.validator.addMethod("checkboxRequired", function (value, element) {
	return $(element).prop('checked');
}, Resources.VALIDATE_REQUIRED);

$.extend($.validator.messages, {
	required: Resources.VALIDATE_REQUIRED,
	remote: Resources.VALIDATE_REMOTE,
	url: Resources.VALIDATE_URL,
	date: Resources.VALIDATE_DATE,
	dateISO: Resources.VALIDATE_DATEISO,
	number: Resources.VALIDATE_NUMBER,
	digits: Resources.VALIDATE_DIGITS,
	creditcard: Resources.VALIDATE_CREDITCARD,
	equalTo: Resources.VALIDATE_EQUALTO,
	emailMatch: Resources.GIFTCERT_EMAILMATCH,
	maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
	minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
	rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
	range: $.validator.format(Resources.VALIDATE_RANGE),
	max: $.validator.format(Resources.VALIDATE_MAX),
	min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
	regex: regex,
	settings: settings,
	init: function (el) {
		var self = this;
		var $el = el ? $(el) : document;
		$('form:not(.suppress):not(.pr-war)', $el).each(function () {
			if ($(this).validate) {
				$(this).validate(self.settings);
			}
		});

		// // Add individual element type required message overrides here.
		$(".confirm-recipient-mail input", $el).each(function () {
			$(this).rules('add', {
				equalTo: ".recipient-mail input",
				messages: {
					equalTo: Resources.GIFTCERT_EMAILMATCH
				}
			});
		});

		$("input.password", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_PASSWORD
				}
			});
		});

		$("input.email", $el).each(function () {
			$(this).rules('add', {
				required: true,
				maxlength: 80,
				messages: {
					required: Resources.VALIDATE_EMAIL,
					maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH)
				}
			});
		});

		$("input.login-email", $el).each(function () {
			$(this).rules('add', {
				required: true,
				email: true,
				messages: {
					required: Resources.VALIDATE_EMAIL
				}
			});
		});

		$("input.firstname", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_FIRSTNAME
				}
			});
		});

		$("input.lastname", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_LASTNAME
				}
			});
		});

		$("input.address1", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_ADDRESS
				}
			});
		});

		$("input.address2", $el).each(function () {
			$(this).rules('add', {
				required: false,
				messages: {
					required: Resources.VALIDATE_ADDRESS
				}
			});
		});

		$("input.city", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_CITY
				}
			});
		});

		$("select.state", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_STATE
				}
			});
		});

		$("input.postalcode", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_POSTALCODE
				}
			});
		});

		$("input.phone", $el).each(function () {
			$(this).rules('add', {
				required: true,
				messages: {
					required: Resources.VALIDATE_PHONE
				}
			});
		});

		$("select.birth-date", $el).each(function () {
			$(this).rules('add', {
				"required": false,
				'birth-monthdate': ['birth-month', 'birth-date'],
				"messages": {
					'birth-monthdate': Resources.VALIDATE_DATE
				}
			});
		});

		if (window.SitePreferences.VALIDATE_DONOTSHIP) {
			$("input.donotship-zipcodes", $el).each(function () {
				const $that = $(this);
				$(this).rules('add', {
					remote: function () {
						return {
							url: util.appendParamToURL(window.Urls.validateDonotshipZipcodes, 'zipcode', $that.val()),
							data: ''
						};
					},
					messages: {
						remote: function () {
							let message = Resources.DONOTSHIP_POSTALCODE;
							if ($(".country:input", $el).val() == "US") {
								message = message.replace("XXX", Resources.ZIP_LABEL);
							} else if ($(".country:input", $el).val() == "UK") {
								message = message.replace("XXX", Resources.POSTCODE_LABEL);
							} else {
								message = message.replace("XXX", Resources.POSTALCODE_LABEL);
							}

							return message;
						}
					}
				});
			});
		}
	},
	initForm: function (f) {
		$(f).validate(this.settings);
	}

};

module.exports = validator;
